import React from "react";
import { Link } from "gatsby";

import Layout from "../../components/layout";

// Assets

const CareersMechanicalEngineer = () => (
  <Layout currentPage="careers-listing">
    <div className="container">
      <Link to="/careers#alljobs">
        <p className="backbutton col-md-12">Back to Careers</p>
      </Link>
      <h1 className="jobtitle col-md-12">Mechanical Engineer</h1>
      <body className="jobdescription col-md-12">
        {" "}
        Making something appear simple is the most complex job. At Uno, we’re
        building a product that’s never been created before, and that requires a
        sense of wonder, patience and incredible commitment to figuring things
        out.
      </body>
      <div className="row col-md-12 justify-content-md-between">
        <div className="col-md-3">
          <body className="aligned-left bold-text jobsectiontitle">
            You will:
          </body>
        </div>
        <div className="col-md-9">
          <ul className="aligned-left jobsection">
          <li>Work with our industrial designers, electrical engineers and manufacturing partners to bring Uno products to the assembly line. </li>
          <li>Work with complex assemblies across multiple disciplines and contribute engineering rigor to the concepting, prototyping, and design of our newest products.</li>
          <li>Be part of an agile team that turns ideas into functioning prototypes and mass produced products. </li>
          <li>Engineer a variety of parts and mechanisms, designing cost-consciously around mold making, materials, actions, cycle times, and part weight.</li>
          <li>Help ensure Uno is delightful to use, reliable and sustainable for the environment.</li>
          </ul>
        </div>

        <div className="col-md-3">
          <body className="aligned-left bold-text jobsectiontitle">
            You are:
          </body>
        </div>
        <div className="col-md-9 ">
          <ul className="aligned-left jobsection">
          <li>Able to balance fantastical with practicality.  </li>
          <li>Not afraid to try things that have never been done before, find new methods and push beyond conventions. </li>
          <li>Natural at synthesizing information and distilling it down.</li>
          <li>Obsessed with details.</li>
          <li>A collaborative person who knows how to harness the strengths of other team members.</li>
          <li>Flexible and can adapt quickly when changes are needed.</li>
          <li>Responsible with your time.</li>
          <li>Familiar with factory environments and working past language barriers.</li>
          </ul>
        </div>

        <div className="col-md-3">
          <body className="aligned-left bold-text jobsectiontitle">
            You bring:
          </body>
        </div>
        <div className="col-md-9 ">
          <ul className="aligned-left jobsection">
          <li>BS/MS/PhD in Mechanical Engineering.</li>
          <li>Excellent understanding of mechanics and intuitive spatial/visual sense.</li>
          <li>Hands-on prototyping, testing and troubleshooting experience.</li>
          <li>Knowledge in material selection and design for reliability.</li>
          <li>Experience conceptualizing, designing, and optimizing a variety of mechanisms.</li>
          <li>Knowledge of materials, process, assembly, electronics and production methods.</li>
          <li>Understanding of testing during different manufacturing stages.</li>
          <li>Proven expertise in getting products from design to assembly line.</li>
          <li>High proficiency with surfacing, top-down modeling, simulation, assemblies and 2D drafting in Solidworks or other industry-standard software. </li>
          <li>Ability to design injection molded, sheet metal, and other component parts.</li>
          <li>Knowledge of materials, process, assembly, electronics and production methods</li>
          <li>Superb mechanical drawing craft (including manufacturing and inspection drawings).</li>
          <li>Experience with sustainably-driven products is a plus.</li>
          <li>Experience in consumer products is a plus.</li>

          </ul>
        </div>

        <div className="col-md-3">
          <body className="aligned-left bold-text jobsectiontitle">
            You apply:
          </body>
        </div>
        <div className="col-md-9">
          <body className="aligned-left" style={{paddingBottom:"100px"}}>
                By sending us an <a href="mailto:careers@introducing.uno">email</a> with your resume and a cover letter.
                </body>
       </div>
      </div>
    </div>
  </Layout>
);

export default CareersMechanicalEngineer;
